import { EditButton } from 'react-admin';
import FieldBlueprint from '../_blueprints/FieldBlueprint';
import ListBlueprint from '../_blueprints/ListBlueprint';
import FilterBlueprint from '../_blueprints/FilterBlueprint';
import hrChallengeData from './hrChallengeData';

const HrChallengeList = (props) => {
  const filters = Object.values(hrChallengeData)
    .filter((x) => x.filterable)
    .map((field) => FilterBlueprint(field));

  return (
    <ListBlueprint {...props} filters={filters} exporter={false} title='hrChallenge.challenges' bulkActionButtons={false}>
      {Object.values(hrChallengeData)
        .filter((x) => x.showInView)
        .map((field) => FieldBlueprint(field))}
      <EditButton />
    </ListBlueprint>
  );
};

export default HrChallengeList;
