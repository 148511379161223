import { useRecordContext } from 'react-admin';
import ShowMultipleChoiceAnswer from './ShowMultipleChoiceAnswer';
import ShowFreeFormTextAnswer from './ShowFreeFormTextAnswer';

const ShowAnswers = () => {
  const record = useRecordContext();

  return (
    <div style={{ paddingTop: '12px' }}>
      {record &&
        record.hrQuestionAnswers.map((q) =>
          q.answerOptionType === 'Dynamic' ? (
            <ShowMultipleChoiceAnswer key={q.id} data={q.answerOptions} title={q.question} total={q.answersTotal} />
          ) : (
            <ShowFreeFormTextAnswer key={q.id} data={q.answerFreeFormTexts} title={q.question} total={q.answersTotal} />
          )
        )}
    </div>
  );
};

export default ShowAnswers;
