import { useCallback } from 'react';
import {
  Edit,
  SimpleForm,
  useUpdate,
  useNotify,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  useRecordContext,
} from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import { formatValidationErrors } from './utils';
import { useNavigate } from 'react-admin/node_modules/react-router-dom';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomToolbar = (props) => {
  const { resource } = props;
  const classes = useStyles();
  const record = useRecordContext();

  const redirectTo = resource === 'hrquestion' ? `/hrsurvey/${record && record.hrSurveyId}` : `/${resource}`;

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton />
      <DeleteWithConfirmButton redirect={redirectTo} />
    </Toolbar>
  );
};

const EditBlueprint = (props) => {
  const { children, resource, redirect, validate, ...restProps } = props;

  const navigate = useNavigate();
  const notify = useNotify();
  const [update] = useUpdate();

  const save = useCallback(
    async (values) => {
      try {
        await update(
          resource,
          {
            data: values,
          },
          { returnPromise: true }
        ).then(() => {
          if (resource === 'hrquestion') {
            navigate(-1);
          }
          notify('notifications.updatedSuccessfully');
        });
      } catch (error) {
        try {
          return formatValidationErrors(error?.body?.errors);
        } catch (e) {
          notify(e.message, { type: 'error' });
        }
      }
    },
    [update, notify, resource, navigate]
  );

  return (
    <Edit resource={resource} {...restProps} redirect={redirect}>
      <SimpleForm onSubmit={save} toolbar={<CustomToolbar resource={resource} />} warnWhenUnsavedChanges mode='onBlur' validate={validate}>
        {children}
      </SimpleForm>
    </Edit>
  );
};

export default EditBlueprint;
