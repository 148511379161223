import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
import finnishMessages from './i18n/fi';
import swedishMessages from './i18n/sv';

const messages: any = {
    en: englishMessages,
    fi: finnishMessages,
    sv: swedishMessages
};

export default polyglotI18nProvider((locale) : any => {
    return messages[locale] ? messages[locale] : messages.en;
}, 'en');