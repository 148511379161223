import { EditButton, ShowButton, useRecordContext } from 'react-admin';
import FieldBlueprint from '../_blueprints/FieldBlueprint';
import ListBlueprint from '../_blueprints/ListBlueprint';
import FilterBlueprint from '../_blueprints/FilterBlueprint';
import hrSurveyData from './hrSurveyData';

const HrSurveyList = (props) => {
  const filters = Object.values(hrSurveyData)
    .filter((x) => x.filterable)
    .map((field) => FilterBlueprint(field));

  const ShowAnswerButton = () => {
    const record = useRecordContext();
    return (
      record.surveyStatus !== 'MissingQuestions' && (
        <ShowButton label='hrAnswer.showAnswers' to={{ pathname: `/hranswer/${record.id}/show` }} />
      )
    );
  };

  return (
    <ListBlueprint {...props} filters={filters} exporter={false} title='hrSurvey.surveys' bulkActionButtons={false}>
      {Object.values(hrSurveyData)
        .filter((x) => x.showInView)
        .map((field) => FieldBlueprint(field))}
      <EditButton />
      <ShowAnswerButton />
    </ListBlueprint>
  );
};

export default HrSurveyList;
