import { useTranslate, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-admin/node_modules/react-router-dom';
import EditBlueprint from '../_blueprints/EditBlueprint';
import InputBlueprint from '../_blueprints/InputBluePrint';
import hrChallengeData, { challengeValidator } from './hrChallengeData';

const PageTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  return (
    <span>
      {translate('hrChallenge.challenge')}: {record && record.title}
    </span>
  );
};

const HrChallengeEdit = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <EditBlueprint
        resource='hrchallenge'
        {...props}
        validate={challengeValidator}
        redirect={() => navigate(-1)}
        title={<PageTitle />}
      >
        {Object.values(hrChallengeData)
          .filter((x) => x.showInView)
          .map((field) => InputBlueprint(field))}
      </EditBlueprint>
    </>
  );
};

export default HrChallengeEdit;
