import { required, regex, maxLength } from 'react-admin';

export const validatorTypes = {
  required: 'required',
  activationCode: 'activationCode',
  businessId: 'businessId',
  maxTextLength50: 'maxTextLength50',
  maxTextLength128: 'maxTextLength128',
  maxTextLength512: 'maxTextLength512',
  maxTextLength2048: 'maxTextLength2048',
};

export const validatorDefinitions = {
  [validatorTypes.required]: required,
  [validatorTypes.activationCode]: () =>
    regex(
      /^[A-Z0-9]{6,8}$/,
      'Activation code must be 6-8 characters long and consist only of uppercase letters and numbers.'
    ),
  [validatorTypes.businessId]: () => regex(/^\d{7}-\d$/, 'Business Id has to follow the pattern 1234567-8.'),
  [validatorTypes.maxTextLength50]: () => maxLength(50),
  [validatorTypes.maxTextLength128]: () => maxLength(128),
  [validatorTypes.maxTextLength512]: () => maxLength(512),
  [validatorTypes.maxTextLength2048]: () => maxLength(2048),
};
