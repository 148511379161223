import ErrorIcon from '@mui/icons-material/Error';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Stop } from '@mui/icons-material';
import { DataType, EntityProps } from '../_blueprints/types';
import { validatorTypes } from '../_blueprints/fieldValidators';

const hrSurveyData: EntityProps = {
  id: {
    dataType: DataType.Text,
    source: 'id',
  },
  title: {
    dataType: DataType.Text,
    source: 'title_Translations',
    label: 'hrSurvey.title',
    localizable: true,
    validators: [validatorTypes.maxTextLength128],
    showInView: true,
  },
  description: {
    dataType: DataType.Text,
    source: 'description_Translations',
    localizable: true,
    label: 'hrSurvey.description',
    showInView: true,
  },
  startDate: {
    dataType: DataType.DateTime,
    source: 'startDate',
    label: 'hrSurvey.startDate',
    validators: [validatorTypes.required],
    showInView: true,
  },
  endDate: {
    dataType: DataType.DateTime,
    source: 'endDate',
    label: 'hrSurvey.endDate',
    validators: [validatorTypes.required],
    showInView: true,
  },
  surveyStatus: {
    dataType: DataType.HRSurveyStatus,
    source: 'surveyStatus',
    showInView: true,
    label: 'hrSurvey.statusLabel',
    icons: {
      MissingQuestions: {
        icon: ErrorIcon,
        color: '#b22222',
      },
      Waiting: {
        icon: AccessTimeIcon,
        color: '#6495ed',
      },
      Live: {
        icon: PlayArrowIcon,
        color: 'green',
      },
      Ended: {
        icon: Stop,
        color: '#000000',
      },
    },
    sortable: false,
  },
  totalUsers: {
    dataType: DataType.Number,
    source: 'totalUsers',
    label: 'hrSurvey.totalRespondents',
    showInView: true,
    textAlign: 'left',
    disabled: true,
  },
};

const surveyValidator = (values: any) => {
  const errors: any = {};

  const missingTranslationError = {
    en: 'errors.titleEnRequired',
    fi: 'errors.titleFiRequired',
  };

  if (!!values) {
    if (!!!Object.values(values.title_Translations).find((lang: any) => lang.length > 0)) {
      errors.title_Translations = missingTranslationError;
    }
  }

  return errors;
};

export { surveyValidator };
export default hrSurveyData;
