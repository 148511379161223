import { useNavigate } from 'react-admin/node_modules/react-router-dom';
import EditBlueprint from '../_blueprints/EditBlueprint';
import InputBlueprint from '../_blueprints/InputBluePrint';
import hrQuestionData, { questionValidator } from './hrQuestionData';

const HrQuestionEdit = (props) => {
  const navigate = useNavigate();

  return (
    <EditBlueprint resource='hrquestion' {...props} redirect={() => navigate(-1)} validate={questionValidator}>
      {Object.values(hrQuestionData)
        .filter((x) => x.showInView && x.source !== 'answerOptionType')
        .map((field) => InputBlueprint(field))}
    </EditBlueprint>
  );
};

export default HrQuestionEdit;
