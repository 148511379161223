import englishMessages from 'ra-language-english';

export const messages = {
  ...englishMessages,
  languages: {
    english: 'In English',
    finnish: 'Suomeksi',
    swedish: 'På svenska'
  },
  wellbeingCompany: {
    calculatedAt: 'Calculated at',
    wellbeingIndex: 'Wellbeing index',
  },
  hrSurvey: {
    surveys: 'Surveys',
    title: 'Title',
    survey: 'Survey',
    description: 'Description',
    startDate: 'Start Date',
    endDate: 'End Date',
    createQuestion: 'Add a question',
    createTitle: 'Create a survey',
    statusLabel: 'Survey status',
    totalRespondents: 'Total respondents',
    status: {
      undefined: '-',
      MissingQuestions: 'Survey requires at least one question.',
      Waiting: 'Survey will be shown after the start date.',
      Live: 'Survey is live.',
      Ended: 'Survey has ended.',
    },
  },
  hrQuestion: {
    questions: 'Questions',
    question: 'Question',
    priority: 'Priority',
    helperText: 'Questions are displayed to users by descending priority.',
    answerOptionType: 'Answer option type',
    answerOptions: 'Answer options',
    answerOptionsInput: 'Answer options (at least two required)',
    dynamic: 'Multiple choice',
    freeForm: 'Free text field',
  },
  hrAnswer: {
    showAnswers: 'Show answers',
    answers: 'Answers',
    total: 'Total',
  },
  hrChallenge: {
    challenges: 'Challenges',
    challenge: 'Challenge',
    title: 'Title',
    activity: 'Activity',
    totalAmountOfActivities: 'Amount of required activities',
    description: 'Description',
    startDate: 'Start Date',
    endDate: 'End Date',
    totalRegisteredActivities: 'Total activities completed',
    totalRegisteredActivitiesUser: 'Total activities completed by user',
    createTitle: 'Create challenge',
  },
  notifications: {
    updatedSuccessfully: 'Updated successfully',
    createdSuccessfully: 'Created successfully',
  },
  errors: {
    validationFailed: 'Validation failed',
    titleEnRequired: 'At least one language version has to be entered.',
    titleFiRequired: 'Vähintään yksi kieliversio on syötettävä.',
    titleSvRequired: 'Minst en språkversion måste anges.'
  },
};

export default messages;
