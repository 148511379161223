import { useRecordContext } from 'react-admin';

const ArrayTextField = ({ source, renderedProperty }) => {
  const record = useRecordContext();
  const answer = record && record[source];
  return (
    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
      {answer && answer.map((item) => <li key={item.answerOptionId}>{item[renderedProperty]}</li>)}
    </ul>
  );
};

export default ArrayTextField;
