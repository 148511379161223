import { EditButton } from 'react-admin';
import FieldBlueprint from '../_blueprints/FieldBlueprint';
import ListBlueprint from '../_blueprints/ListBlueprint';
import FilterBlueprint from '../_blueprints/FilterBlueprint';
import hrQuestionData from './hrQuestionData';

const HrQuestionList = (props) => {
  // PermanentFilter was added because the filter prop was always lost when passed from hrSurveyEdit to this component.
  // TODO: Check if this bug has been fixed when updating to the next major version of React Admin.
  const { permanentfilter, ...restProps } = props;

  const filters = Object.values(hrQuestionData)
    .filter((x) => x.filterable)
    .map((field) => FilterBlueprint(field));

  return (
    <ListBlueprint
      {...restProps}
      hasCreate={true}
      filter={permanentfilter}
      filters={filters}
      title=' '
      sort={{ field: 'priority', order: 'DESC' }}
      bulkActionButtons={false}
    >
      {Object.values(hrQuestionData)
        .filter((x) => x.showInView)
        .map((field) => FieldBlueprint(field))}
      <EditButton />
    </ListBlueprint>
  );
};

export default HrQuestionList;
