import CreateBluePrint from '../_blueprints/CreateBlueprint';
import InputBlueprint from '../_blueprints/InputBluePrint';
import hrSurveyData, { surveyValidator } from './hrSurveyData';

const HrSurveyCreate = (props) => {
  return (
    <CreateBluePrint resource='hrsurvey' {...props} title='hrSurvey.createTitle' validate={surveyValidator}>
      {Object.values(hrSurveyData)
        .filter((x) => x.showInView && x.source !== 'surveyStatus' && x.source !== 'totalUsers')
        .map((field) => InputBlueprint(field))}
    </CreateBluePrint>
  );
};

export default HrSurveyCreate;
