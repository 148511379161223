import { LANGUAGES, LANGUAGE_OPTIONS } from './lib/constants';

const LANGUAGE = 'language';

export function getLocale() {
  const locale = window.localStorage.getItem('RaStore.locale') && window.localStorage.getItem('RaStore.locale').replace(/"/g, '');
  // if no language set to local storage, use english
  return LANGUAGES.some((language) => language.locale === locale)
    ? locale : 'en';
}

export function getLanguage() {
  const language = window.localStorage.getItem(LANGUAGE);
  // if no language set to local storage, use english
  return LANGUAGE_OPTIONS.some((option) => option.value === language)
    ? language : 'en';
}

export function setLanguage(value) {
  if (!LANGUAGE_OPTIONS.some((option) => option.value === value)) {
    throw new Error('Invalid language!');
  }
  window.localStorage.setItem(LANGUAGE, value);
}