import CreateBluePrint from '../_blueprints/CreateBlueprint';
import InputBlueprint from '../_blueprints/InputBluePrint';
import hrChallengeData, { challengeValidator } from './hrChallengeData';

const HrChallengeCreate = (props) => {
  return (
    <CreateBluePrint resource='hrchallenge' {...props} title='hrChallenge.createTitle' validate={challengeValidator}>
      {Object.values(hrChallengeData)
        .filter((x) => x.showInView && x.source !== 'totalRegisteredActivities')
        .map((field) => InputBlueprint(field))}
    </CreateBluePrint>
  );
};

export default HrChallengeCreate;
