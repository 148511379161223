import {
  BooleanInput,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
} from 'react-admin';
import { DataType } from './types';

const fieldTypes = {
  [DataType.Bool]: ({ source, label }) => {
    return <BooleanInput key={source} source={source} label={label} />;
  },
  [DataType.Text]: ({ source, label }) => <TextInput key={source} source={source} label={label} />,
  [DataType.Number]: ({ source, label }) => <NumberInput key={source} source={source} label={label} />,
  [DataType.Reference]: ({ source, reference, label, renderedProperty }) => {
    return (
      <ReferenceInput key={source} source={source} reference={reference} label={label} allowEmpty={false}>
        <AutocompleteInput optionText={renderedProperty ?? 'name'} />
      </ReferenceInput>
    );
  },
  [DataType.ReferenceArray]: ({ source, reference, label }) => {
    return (
      <ReferenceArrayInput key={source} reference={reference} source={source} label={label} allowEmpty={false}>
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
    );
  },
};

const FilterBlueprint = ({ dataType, source, label, reference, renderedProperty }) => {
  return fieldTypes[dataType]({
    source,
    label,
    reference,
    renderedProperty,
  });
};

export default FilterBlueprint;
