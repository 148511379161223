import { List, Datagrid, BulkDeleteButton } from 'react-admin';

const ListBlueprint = (props) => {
  const { children, resource, dataSource, filters, options, actions, ...restProps } = props;
  //const { defaultListSort } = options;

  return (
    <List
      actions={actions}
      resource={resource}
      filters={filters}
      //sort={defaultListSort}
      {...restProps}
    >
      <Datagrid bulkActionButtons={<BulkDeleteButton resource={resource} undoable='false' />}>{children}</Datagrid>
    </List>
  );
};

export default ListBlueprint;
