import {
  TextField,
  BooleanField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceField,
  NumberField,
  ImageField,
  DateField,
  SelectField,
  useLocaleState,
} from 'react-admin';
import { DataType } from './types';
import ArrayTextField from '../../customComponents/ArrayTextField';
import IconField from '../../customComponents/IconField';

const fieldTypes = {
  [DataType.Bool]: ({ source, defaultValue, label, sortable }) => (
    <BooleanField key={source} source={source} defaultValue={defaultValue} label={label} sortable={sortable} />
  ),
  [DataType.Text]: ({ source, label, sortable }) => (
    <TextField key={source} source={source} label={label} sortable={sortable} />
  ),
  [DataType.Number]: ({ source, label, sortable, textAlign }) => (
    <NumberField key={source} source={source} label={label} sortable={sortable} textAlign={textAlign} />
  ),
  [DataType.Image]: ({ source, label, sortable }) => (
    <ImageField key={source} source={source} label={label} sortable={sortable} />
  ),
  [DataType.Date]: ({ source, label, sortable, locale }) => (
    <DateField key={source} source={source} label={label} locales={locale} sortable={sortable} />
  ),
  [DataType.DateTime]: ({ source, label, sortable, locale }) => (
    <DateField key={source} source={source} label={label} locales={locale} sortable={sortable} />
  ),
  [DataType.Reference]: ({ source, label, reference, renderedProperty, sortable, link }) => (
    <ReferenceField key={source} label={label} reference={reference} source={source} sortable={sortable} link={link}>
      <ChipField source={renderedProperty} />
    </ReferenceField>
  ),
  [DataType.ReferenceArray]: ({ source, label, reference, renderedProperty, sortable }) => (
    <ReferenceArrayField key={source} label={label} reference={reference} source={source} sortable={sortable}>
      <SingleFieldList>
        <ChipField source={renderedProperty} />
      </SingleFieldList>
    </ReferenceArrayField>
  ),
  [DataType.ReferenceImage]: ({ source, label, reference, sortable }) => (
    <ReferenceField key={source} label={label} reference={reference} source={source} sortable={sortable}>
      <ImageField source={source} />
    </ReferenceField>
  ),
  [DataType.Select]: ({ source, label, choices, sortable }) => (
    <SelectField key={source} source={source} label={label} choices={choices} sortable={sortable} />
  ),
  [DataType.Array]: ({ source, label, renderedProperty, sortable }) => (
    <ArrayTextField
      key={source}
      source={source}
      renderedProperty={renderedProperty}
      label={label}
      sortable={sortable}
    />
  ),
  [DataType.Answer]: ({ source, label, renderedProperty, sortable }) => (
    <ArrayTextField
      key={source}
      source={source}
      renderedProperty={renderedProperty}
      label={label}
      sortable={sortable}
    />
  ),
  [DataType.HRSurveyStatus]: ({ source, label, icons, sortable }) => (
    <IconField key={source} source={source} label={label} icons={icons} sortable={sortable} />
  ),
};

const FieldBlueprint = ({
  dataType,
  source,
  defaultValue,
  label,
  reference,
  renderedProperty,
  localizable,
  choices,
  icons,
  sortable,
  textAlign,
  link,
}) => {
  const [locale] = useLocaleState();
  // In case the field is translatable, use the base field returned by the backend by removing _Translation from the name of the field.
  // This is done to ensure backwards compatibility with backend until all translatable fields are returned properly.
  const newSource = localizable ? source.slice(0, source.indexOf('_')) : source;

  return fieldTypes[dataType]({
    source: newSource,
    defaultValue,
    label,
    reference,
    renderedProperty,
    choices,
    icons,
    sortable,
    textAlign,
    locale,
    link,
  });
};

export default FieldBlueprint;
