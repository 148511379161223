import { useWatch } from 'react-admin/node_modules/react-hook-form';
import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';

const AnswerComponent = ({ source, locales, validators }) => {
  const watch = useWatch();

  return (
    <>
      {watch.answerOptionType === 'Dynamic' && (
        <ArrayInput source={source} label='hrQuestion.answerOptionsInput'>
          <SimpleFormIterator disableReordering>
            {locales.map((locale) => (
              <TextInput
                key={`translation_${locale}`}
                source={`translations.${locale}`}
                label={locale.toUpperCase()}
                validate={validators}
                style={{ width: '50%' }}
              />
            ))}
          </SimpleFormIterator>
        </ArrayInput>
      )}
    </>
  );
};

export default AnswerComponent;
