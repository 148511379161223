import { PublicClientApplication } from '@azure/msal-browser';
import { fetchUtils, DataProvider } from 'react-admin/node_modules/ra-core';
import jsonServerProvider from 'ra-data-json-server';
import { authConfig } from './authConfig';
import { getLocale } from './localStorage';

const httpClient = async (url: string, options: any = {}) => {
  const msalInstance = new PublicClientApplication(authConfig);
  const accounts = msalInstance.getAllAccounts();
  const request = {
    scopes: process.env.REACT_APP_B2C_SCOPES?.split(',').map((scope) => scope.trim()) || [],
    account: accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  const token = authResult.accessToken;

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', `Bearer ${token}`);
  options.headers.set('Accept-Language', getLocale());
  return fetchUtils.fetchJson(url, options);
};

const mydataProvider = (apiUrl: string | undefined): DataProvider => {
  const provider = jsonServerProvider(apiUrl, httpClient);

  provider.getOne = (resource, params) => {
    // use custom endpoint for fetching HR survey answers
    const url = resource === 'hranswer' ? `${apiUrl}/hrsurvey/${params.id}/hranswers` : `${apiUrl}/${resource}/${params.id}`;

    return httpClient(url, {
      method: 'GET',
    }).then(({ json }) => {
      const data = json;
      return ({ data })
    });
  };

  // Update function is replaced by a custom one because the original expects to have id of the resource in URL.
  provider.update = (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));

  return provider;
};

export default mydataProvider;
