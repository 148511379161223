import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 20,
  },
  bar: {
    height: '100%',
  },
});

const PercentageBar = (props) => {
  const { value, color } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.bar} style={{ maxWidth: `${value}%`, backgroundColor: color }} />
    </div>
  );
};

export default PercentageBar;
