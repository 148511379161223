import { useCallback } from 'react';
import { Create, SimpleForm, useCreate, useNotify, useRedirect } from 'react-admin';
import { useNavigate } from 'react-admin/node_modules/react-router-dom';
import { formatValidationErrors } from './utils';

const CreateBluePrint = (props) => {
  const { children, resource, validate, ...restProps } = props;
  const navigate = useNavigate();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const [create] = useCreate();

  const save = useCallback(
    async (values) => {
      try {
        await create(resource, { data: values }, { returnPromise: true }).then((data) => {
          if (resource === 'hrquestion') {
            navigate(-1);
          } else {
            redirectTo('edit', resource, data.id, data);
          }

          notify('notifications.createdSuccessfully');
        });
      } catch (error) {
        try {
          return formatValidationErrors(error?.body?.errors);
        } catch (e) {
          notify(e.message, { type: 'error' });
        }
      }
    },
    [create, notify, redirectTo, resource, navigate]
  );

  return (
    <Create resource={resource} {...restProps}>
      <SimpleForm onSubmit={save} mode='onBlur' validate={validate}>
        {children}
      </SimpleForm>
    </Create>
  );
};

export default CreateBluePrint;
