import { useRecordContext, useTranslate } from 'react-admin';
import CustomTooltip from './CustomTooltip';

const IconField = ({ source, icons }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const icon = icons[record[source]];
  return (
    <>
      <CustomTooltip title={translate(`hrSurvey.status.${record[source]}`)}>
        <span style={{ color: icon.color }}>{icon.icon.type.render()}</span>
      </CustomTooltip>
    </>
  );
};

export default IconField;
