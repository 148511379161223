import CreateBluePrint from '../_blueprints/CreateBlueprint';
import InputBlueprint from '../_blueprints/InputBluePrint';
import hrQuestionData, { questionValidator } from './hrQuestionData';

const HrQuestionCreate = (props) => (
  <CreateBluePrint resource='hrquestion' {...props} validate={questionValidator}>
    {Object.values(hrQuestionData)
      .filter((x) => x.showInView)
      .map((field) => InputBlueprint(field))}
  </CreateBluePrint>
);

export default HrQuestionCreate;
