import { LocalesMenuButton, AppBar } from 'react-admin';
import { Box, Typography } from '@mui/material/';
import { LANGUAGES } from '../../lib/constants';

const CustomAppBar = (props) => {
  return (
    <AppBar {...props}>
      <Box flex='1'>
        <Typography variant='h6' id='react-admin-title' />
      </Box>
      <LocalesMenuButton languages={LANGUAGES} />
    </AppBar>
  );
};

export default CustomAppBar;
