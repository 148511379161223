import { ComponentType } from 'react';

interface Sort {
  field: string;
  order: 'ASC' | 'DESC';
}

export interface IconMap {
  [key: string]: {
    color: string;
    icon: any;
  }
}

export type EntityProps = {
  [key: string]: {
    dataType: DataType;
    source: string;
    label?: string;
    showInView?: boolean;
    defaultValue?: any;
    validators?: any;
    helperText?: string;
    reference?: string;
    renderedProperty?: string;
    filterable?: boolean;
    allowEmpty?: boolean; // Controls whether user can choose not to make a selection for reference
    maxSize?: number;
    suggestionSort?: Sort;
    localizable?: boolean;
    choices?: any;
    icons?: IconMap;
    sortable?: boolean;
    textAlign?: string;
    disabled?: boolean;
    link?: boolean;
    min?: number
  };
};

interface AssetOptions {
  label?: string;
  defaultListSort?: Sort;
}

export interface Assets {
  list: (props: any) => JSX.Element;
  create?: (props: any) => JSX.Element;
  edit?: (props: any) => JSX.Element;
  show?: (props: any) => JSX.Element;
  icon?: ComponentType<any>;
  options?: AssetOptions;
}

export enum DataType {
  Bool = 'bool',
  Text = 'text',
  Number = 'number',
  Image = 'image',
  Date = 'date',
  DateTime = 'dateTime',
  Reference = 'reference',
  ReferenceArray = 'referenceArray',
  ReferenceImage = 'referenceImage',
  Select = 'select',
  Array = 'array',
  Answer = 'answer',
  HRSurveyStatus = 'hRSurveyStatus'
}
