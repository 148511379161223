import { Assets } from '../_blueprints/types';
import hrChallengeList from './hrChallengeList';
import hrChallengeEdit from './hrChallengeEdit';
import hrChallengeCreate from './hrChallengeCreate';

const hrChallengeAssets: Assets = {
  list: hrChallengeList,
  edit: hrChallengeEdit,
  create: hrChallengeCreate,
  options: {
    label: 'hrChallenge.challenges'
  }
};

export default hrChallengeAssets;