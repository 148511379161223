import finnishMessages from 'ra-language-finnish';

// ra.navigation.prev is missing from finnish translation
export const messages = {
  ra: {
    ...finnishMessages.ra,
    navigation: {
      ...finnishMessages.ra.navigation,
      prev: 'Edellinen sivu',
    },
  },
  languages: {
    english: 'In English',
    finnish: 'Suomeksi',
    swedish: 'På svenska'
  },
  wellbeingCompany: {
    calculatedAt: 'Laskettu',
    wellbeingIndex: 'Hyvinvointi-indeksi',
  },
  hrSurvey: {
    surveys: 'Kyselyt',
    title: 'Otsikko',
    survey: 'Kysely',
    description: 'Kuvaus',
    startDate: 'Alkamisaika',
    endDate: 'Loppumisaika',
    createQuestion: 'Lisää kysymys',
    createTitle: 'Lisää kysely',
    statusLabel: 'Tila',
    totalRespondents: 'Vastaajia yhteensä',
    status: {
      undefined: '-',
      MissingQuestions: 'Kysely vaatii vähintään yhden kysymyksen.',
      Waiting: 'Kysely tullaan näyttämään käyttäjille alkamisaikaan.',
      Live: 'Kysely on käynnissä.',
      Ended: 'Kysely on päättynyt.',
    },
  },
  hrQuestion: {
    questions: 'Kysymykset',
    question: 'Kysymys',
    priority: 'Prioriteetti',
    helperText: 'Kysymykset näytetään käyttäjille laskevan prioriteetin mukaan.',
    answerOptionType: 'Vastausvaihtoehdon tyyppi',
    answerOptions: 'Vastausvaihtoehdot',
    answerOptionsInput: 'Vastausvaihtoehdot (vähintään kaksi vaaditaan)',
    dynamic: 'Monivalinta',
    freeForm: 'Vapaa tekstikenttä',
  },
  hrAnswer: {
    showAnswers: 'Näytä vastaukset',
    answers: 'Vastausta',
    total: 'Yhteensä',
  },
  hrChallenge: {
    challenges: 'Haasteet',
    challenge: 'Haaste',
    title: 'Otsikko',
    activity: 'Aktiviteetti',
    totalAmountOfActivities: 'Vaadittujen aktiviteettien määrä',
    description: 'Kuvaus',
    startDate: 'Alkamisaika',
    endDate: 'Loppumisaika',
    totalRegisteredActivities: 'Suoritettuja aktiviteetteja yhteensä',
    totalRegisteredActivitiesUser: 'Käyttäjällä suoritettuja aktiviteetteja yhteensä',
    createTitle: 'Lisää haaste',
  },
  notifications: {
    updatedSuccessfully: 'Päivitetty onnistuneesti',
    createdSuccessfully: 'Luotu onnistuneesti',
  },
  errors: {
    validationFailed: 'Validointi epäonnistui',
    titleEnRequired: 'At least one language version has to be entered.',
    titleFiRequired: 'Vähintään yksi kieliversio on syötettävä.',
    titleSvRequired: 'Minst en språkversion måste anges.'
  },
};

export default messages;
