export const LANGUAGE_OPTIONS = [
  { value: 'en', label: 'english' },
  { value: 'fi', label: 'finnish' },
  { value: 'sv', label: 'swedish' }
];

export const LANGUAGES = [
  { locale: 'en', name: 'In English' },
  { locale: 'fi', name: 'Suomeksi' },
  { locale: 'sv', name: 'På svenska' }
];


export const COLORS = [ 
  '#FF7A00',
  '#3C46A3',
  '#DC6A6F',
  '#3CA384',
  '#2A97B9',
  '#FFBE15',
  '#FFB170',
  '#7890E5',
  '#F18C8C',
  '#61C1A5',
  '#87D0DF',
  '#FFD460',
];

export const COLORS_OBJECT = { 
  orange1: COLORS[0],
  purple1: COLORS[1],
  red1: COLORS[2],
  green: COLORS[3],
  turquoise1: COLORS[4],
  yellow1: COLORS[5],
  orange2: COLORS[6],
  purple2: COLORS[7],
  red2: COLORS[8],
  lightGreen: COLORS[9],
  turquoise2: COLORS[10],
  yellow2: COLORS[11],
};