import { Assets } from '../_blueprints/types';
import hrQuestionList from './hrQuestionList';
import hrQuestionEdit from './hrQuestionEdit';
import hrQuestionCreate from './hrQuestionCreate';

const hrQuestionAssets: Assets = {
  list: hrQuestionList,
  edit: hrQuestionEdit,
  create: hrQuestionCreate,
  options: {
    label: 'hrQuestion.questions'
  }
};

export default hrQuestionAssets;