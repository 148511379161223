import { lowerFirst, isPlainObject } from 'lodash';

// Formats validation error responses found in error.body.errors to format expected by React Admin.
// Requires name of the property to be returned by the backend.
// If any of the names is empty or errors is not an object, an exception is thrown.
export const formatValidationErrors = (errors) => {
  if (isPlainObject(errors) && Object.keys(errors).every((key) => !!key)) {
    var validationErrors = {};

    Object.entries(errors).forEach(([property, errorMessages]) => {
      if (Array.isArray(errorMessages)) {
        validationErrors[lowerFirst(property)] = errorMessages.join(' ');
      } else {
        validationErrors[lowerFirst(property)] = errorMessages;
      }
    });

    return validationErrors;
  }

  if (isPlainObject(errors)) {
    var validationMessages = Object.values(errors);
    throw new Error(`Validation failed with the following messages: ${validationMessages}`);
  }

  throw new Error(`Validation failed: ${errors}`);
};
