import React from 'react';
import { useTranslate } from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, TableContainer, Table, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';

const useStyles = makeStyles({
  container: {
    padding: '16px 16px 40px 16px',
  },
  title: {
    fontWeight: 'normal',
    fontSize: '14px',
  },
  separator: {
    fontSize: '20px',
    margin: '0 16px 0 16px',
    color: 'lightGrey',
  },
});

const ShowFreeFormTextAnswer = (props) => {
  const { data, title, total } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        <span style={{ fontWeight: 'bold' }}>{title}</span>
        <span className={classes.separator}>|</span>
        {total} {translate('hrAnswer.answers')}
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((text, id) => (
              <TableRow key={id}>
                <TableCell>{text}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {total > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
          labelDisplayedRows={({ from, to, count }) => `${from} - ${to} / ${count}`}
          showFirstButton
          showLastButton
        />
      )}
    </div>
  );
};

export default ShowFreeFormTextAnswer;
