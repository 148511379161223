import finnishMessages from 'ra-language-finnish';

// ra.navigation.prev is missing from finnish translation
export const messages = {
  ra: {
    ...finnishMessages.ra,
    navigation: {
      ...finnishMessages.ra.navigation,
      prev: 'Föregående sida',
    },
  },
  languages: {
    english: 'In English',
    finnish: 'Suomeksi',
    swedish: 'På svenska'
  },
  wellbeingCompany: {
    calculatedAt: 'Beräknat',
    wellbeingIndex: 'Välfärdsindex',
  },
  hrSurvey: {
    surveys: 'Undersökning',
    title: 'Rubrik',
    survey: 'Undersökning',
    description: 'Beskrivning',
    startDate: 'Starttid',
    endDate: 'Sluttid',
    createQuestion: 'Lägg till fråga',
    createTitle: 'Lägg till undersökning',
    statusLabel: 'Status',
    totalRespondents: 'Totalt antal respondenter',
    status: {
      undefined: '-',
      MissingQuestions: 'Undersökningen måste ha åtminstone en fråga.',
      Waiting: 'Undersökningen visas för användarna vid starttiden.',
      Live: 'Undersökningen pågår.',
      Ended: 'Undersökningen har avslutats.',
    },
  },
  hrQuestion: {
    questions: 'Frågor',
    question: 'Fråga',
    priority: 'Prioritet',
    helperText: 'Frågorna visas för användarna i fallande prioritet.',
    answerOptionType: 'Svarstyp',
    answerOptions: 'Svarsalternativ',
    answerOptionsInput: 'Svarsalternativ (minst två krävs)',
    dynamic: 'Flera val',
    freeForm: 'Fritt textfält',
  },
  hrAnswer: {
    showAnswers: 'Visa svar',
    answers: 'Antal svar',
    total: 'Totalt',
  },
  hrChallenge: {
    challenges: 'Utmaningar',
    challenge: 'Utmaning',
    title: 'Rubrik',
    activity: 'Aktivitet',
    totalAmountOfActivities: 'Antal krävda aktiviteter',
    description: 'Beskrivning',
    startDate: 'Starttid',
    endDate: 'Sluttid',
    totalRegisteredActivities: 'Totalt utförda aktiviteter',
    totalRegisteredActivitiesUser: 'Totalt utförda aktiviteter för användaren',
    createTitle: 'Lägg till utmaning',
  },
  notifications: {
    updatedSuccessfully: 'Notiser uppdatering lyckades',
    createdSuccessfully: 'Skapande lyckades',
  },
  errors: {
    validationFailed: 'Felmeddelanden Validering misslyckades',
    titleEnRequired: 'At least one language version has to be entered.',
    titleFiRequired: 'Vähintään yksi kieliversio on syötettävä.',
    titleSvRequired: 'Minst en språkversion måste anges.'
  },
};

export default messages;
