import React from 'react';
import { Tooltip } from '@mui/material';

const TooltipWrapper = React.forwardRef(({ children, ...props }, ref) => (
  <div ref={ref} {...props}>
    {React.cloneElement(children, props)}
  </div>
));

const CustomTooltip = ({ children, ...props }) => (
  <Tooltip {...props}>
    <TooltipWrapper>{children}</TooltipWrapper>
  </Tooltip>
);

export default CustomTooltip;
