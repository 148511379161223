import { DataType, EntityProps } from '../_blueprints/types';
import { validatorTypes } from '../_blueprints/fieldValidators';

const hrQuestionData: EntityProps = {
  id: {
    dataType: DataType.Text,
    source: 'id',
  },
  question: {
    dataType: DataType.Text,
    source: 'question_Translations',
    label: 'hrQuestion.question',
    localizable: true,
    validators: [validatorTypes.maxTextLength128],
    showInView: true,
  },
  priority: {
    dataType: DataType.Number,
    source: 'priority',
    label: 'hrQuestion.priority',
    showInView: true,
    helperText: 'hrQuestion.helperText',
  },
  hrSurveyId: {
    dataType: DataType.Reference,
    source: 'hrSurveyId',
    showInView: false,
    label: 'hrSurvey.survey',
    reference: 'hrsurvey',
    renderedProperty: 'title',
    allowEmpty: true,
  },
  answerOptionType: {
    dataType: DataType.Select,
    source: 'answerOptionType',
    label: 'hrQuestion.answerOptionType',
    choices: [
      { id: 'Dynamic', name: 'hrQuestion.dynamic' },
      { id: 'FreeForm', name: 'hrQuestion.freeForm' },
    ],
    validators: [validatorTypes.required, validatorTypes.maxTextLength128],
    showInView: true,
  },
  answerOptions: {
    dataType: DataType.Answer,
    source: 'answerOptions',
    renderedProperty: 'answerOption',
    label: 'hrQuestion.answerOptions',
    showInView: true,
    sortable: false,
  },
};

const questionValidator = (values: any) => {
  const errors: any = {};

  const missingTranslationError = {
    en: 'errors.titleEnRequired',
    fi: 'errors.titleFiRequired',
  };

  if (!!values) {
    if (Object.values(values.question_Translations).find((lang: any) => lang.length > 0) === undefined) {
      errors.question_Translations = missingTranslationError;
    }

  }

  return errors;
};

export { hrQuestionData, questionValidator };
export default hrQuestionData;
