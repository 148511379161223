import FieldBlueprint from '../_blueprints/FieldBlueprint';
import ListBlueprint from '../_blueprints/ListBlueprint';
import wellbeingcompanyData from './wellbeingCompanyData';

const WellbeingCompanyList = (props) => (
  <ListBlueprint {...props}>
    {Object.values(wellbeingcompanyData)
      .filter((x) => x.showInView)
      .map((field) => FieldBlueprint(field))}
  </ListBlueprint>
);

export default WellbeingCompanyList;
