import { useTranslate } from 'react-admin';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import PercentageBar from '../customComponents/PercentageBar';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from '../../lib/constants';

const columns = [
  { label: '', key: 'answerOption', align: 'left' },
  { label: 'Total', key: 'answerOptionTotal', align: 'left' },
  { label: '%', key: 'answerOptionPercentage', align: 'left' },
  { label: '0 % - 100%', key: 'answerOptionPercentageBar', align: 'left' },
];

const useStyles = makeStyles({
  container: {
    padding: '16px 16px 40px 16px',
  },
  title: {
    fontWeight: 'normal',
    fontSize: '14px',
  },
  separator: {
    fontSize: '20px',
    margin: '0 16px 0 16px',
    color: 'lightGrey',
  },
  tableCell: {
    padding: '9px 16px',
  },
});

const ShowMultipleChoiceAnswer = (props) => {
  const { data, title, total } = props;
  const classes = useStyles();
  const translate = useTranslate();

  const sortedData = data.sort((a, b) => b.answerOptionTotal - a.answerOptionTotal);

  const getColor = (ind) => {
    return COLORS[ind];
  };

  // used for selecting percentage bar color from colors array
  let colorIndex = -1;

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        <span style={{ fontWeight: 'bold' }}>{title}</span>
        <span className={classes.separator}>|</span>
        {total} {translate('hrAnswer.answers')}
      </Typography>
      {total > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: '30%' }} align='left'>
                  {''}
                </TableCell>
                <TableCell className={classes.tableCell} style={{ width: '5%' }} align='left'>
                  {translate('hrAnswer.total')}
                </TableCell>
                <TableCell className={classes.tableCell} style={{ width: '5%' }} align='left'>
                  {'%'}
                </TableCell>
                <TableCell className={classes.tableCell} style={{ width: '60%' }} align='left'>
                  {'0 % - 100 %'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row) => {
                colorIndex++;
                // start using colors from the beginning, when all the available colors are used
                if (colorIndex === COLORS.length) {
                  colorIndex = 0;
                }

                return (
                  <TableRow key={row.id}>
                    {columns.map((col) => (
                      <TableCell className={classes.tableCell} key={col.key} align='left'>
                        {col.key === 'answerOptionPercentageBar' ? (
                          <PercentageBar value={row['answerOptionPercentage']} color={getColor(colorIndex)} />
                        ) : col.key === 'answerOptionPercentage' ? (
                          `${row[col.key]}\u00A0%`
                        ) : (
                          row[col.key]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ShowMultipleChoiceAnswer;
