import React from 'react';
import { Admin, AuthProvider, Layout, Resource } from 'react-admin';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import DataProvider from './dataProvider';
import i18nProvider from './i18nProvider';
import { authConfig } from './authConfig';
import wellbeingCompanyAssets from './components/assets/wellbeingCompany';
import hrSurveyAssets from './components/assets/hrSurvey';
import hrQuestionAssets from './components/assets/hrQuestion';
import hrChallengeAssets from './components/assets/hrChallenge';
import hrAnswerShow from './components/assets/hrAnswer/hrAnswerShow';
import CustomAppBar from './components/customComponents/CustomAppBar';

const dataProvider = DataProvider(process.env.REACT_APP_WELLTHYFORCE_API);

const msalInstance = new PublicClientApplication(authConfig);

// Custom authentication provider provides only the logout functionality to hook it up with React Admin.
// MsalAuthenticationTemplate handles checking for authentication and authentication redirect.
const authProvider: AuthProvider = {
  login: () => Promise.resolve(),
  logout: () => msalInstance.logoutRedirect(),
  checkAuth: () => Promise.resolve(),
  checkError: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(),
};

const CustomLayout = (props: any) => <Layout {...props} appBar={CustomAppBar} />;

const App = () => (
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Admin authProvider={authProvider} dataProvider={dataProvider} i18nProvider={i18nProvider} layout={CustomLayout}>
          <Resource name='wellbeingindex/company' {...wellbeingCompanyAssets} />
          <Resource name='hrsurvey' {...hrSurveyAssets} />
          {/* HR questions have to be defined as a resource to allow React-Admin to interact with them.
              However, the list property been omitted to hide the questions from the menu. */}
          <Resource name='hrquestion' edit={hrQuestionAssets.edit} create={hrQuestionAssets.create} />
          <Resource name='hranswer' show={hrAnswerShow} />
          <Resource name='hrchallenge' {...hrChallengeAssets} />
        </Admin>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>
);

export default App;
