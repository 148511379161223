import { Assets } from '../_blueprints/types';
import hrSurveyList from './hrSurveyList';
import hrSurveyEdit from './hrSurveyEdit';
import hrSurveyCreate from './hrSurveyCreate';

const hrSurveyAssets: Assets = {
  list: hrSurveyList,
  edit: hrSurveyEdit,
  create: hrSurveyCreate,
  options: {
    label: 'hrSurvey.surveys'
  }
};

export default hrSurveyAssets;