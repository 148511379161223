import { DataType, EntityProps } from '../_blueprints/types';

const wellbeingcompanyData: EntityProps = {
  calculatedAt: {
    dataType: DataType.Date,
    source: 'calculatedAt',
    showInView: true,
    label: 'wellbeingCompany.calculatedAt',
  },
  index: {
    dataType: DataType.Number,
    source: 'index',
    showInView: true,
    label: 'wellbeingCompany.wellbeingIndex',
  },
};

export default wellbeingcompanyData;
