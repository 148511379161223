import { Show, ReferenceManyField, CreateButton, useTranslate, useRecordContext } from 'react-admin';
import { useNavigate, useParams } from 'react-admin/node_modules/react-router-dom';
import EditBlueprint from '../_blueprints/EditBlueprint';
import InputBlueprint from '../_blueprints/InputBluePrint';
import hrSurveyData, { surveyValidator } from './hrSurveyData';
import HrQuestionList from '../hrQuestion/hrQuestionList';

const PageTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  return (
    <span>
      {translate('hrSurvey.survey')}: {record && record.title}
    </span>
  );
};

const HrSurveyEdit = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const HRQuestionCreateButton = () => {
    return (
      <CreateButton
        label='hrSurvey.createQuestion'
        to={{ pathname: '/hrquestion/create', state: { record: { hrSurveyId: parseInt(id) } } }}
      />
    );
  };

  return (
    <>
      <EditBlueprint
        resource='hrsurvey'
        {...props}
        redirect={() => navigate(-1)}
        title={<PageTitle />}
        validate={surveyValidator}
      >
        {Object.values(hrSurveyData)
          .filter((x) => x.showInView)
          .map((field) => InputBlueprint(field))}
      </EditBlueprint>
      <Show actions={false} title={' '}>
        <ReferenceManyField addLabel={false} reference='hrquestion' target='hrSurveyId' empty='No questions'>
          <HrQuestionList
            {...props}
            empty={false}
            actions={<HRQuestionCreateButton />}
            permanentfilter={{ hrSurveyId: id }}
          />
        </ReferenceManyField>
      </Show>
    </>
  );
};

export default HrSurveyEdit;
