import { DataType, EntityProps } from '../_blueprints/types';
import { validatorTypes } from '../_blueprints/fieldValidators';

const hrChallengeData: EntityProps = {
  id: {
    dataType: DataType.Text,
    source: 'id',
  },
  title: {
    dataType: DataType.Text,
    source: 'title_Translations',
    label: 'hrChallenge.title',
    localizable: true,
    validators: [validatorTypes.maxTextLength128],
    showInView: true,
  },
  description: {
    dataType: DataType.Text,
    source: 'description_Translations',
    localizable: true,
    label: 'hrChallenge.description',
    showInView: true,
  },
  activityId: {
    dataType: DataType.Reference,
    source: 'activityId',
    showInView: true,
    label: 'hrChallenge.activity',
    reference: 'activity',
    renderedProperty: 'name',
    link: false,
  },
  totalAmountOfActivities: {
    dataType: DataType.Number,
    source: 'totalAmountOfActivities',
    label: 'hrChallenge.totalAmountOfActivities',
    showInView: true,
    defaultValue: 1,
    textAlign: 'left',
    min: 1,
  },
  startDate: {
    dataType: DataType.DateTime,
    source: 'startDate',
    label: 'hrChallenge.startDate',
    validators: [validatorTypes.required],
    showInView: true,
  },
  endDate: {
    dataType: DataType.DateTime,
    source: 'endDate',
    label: 'hrChallenge.endDate',
    validators: [validatorTypes.required],
    showInView: true,
  },
  totalRegisteredActivities: {
    dataType: DataType.Number,
    source: 'totalRegisteredActivities',
    label: 'hrChallenge.totalRegisteredActivities',
    showInView: true,
    textAlign: 'left',
    disabled: true,
  },
  /* totalRegisteredActivitiesUser: {
    dataType: DataType.Number,
    source: 'totalRegisteredActivitiesUser',
    label: 'hrChallenge.totalRegisteredActivitiesUser',
    showInView: true,
    textAlign: 'left',
  }, */
};

const challengeValidator = (values: any) => {
  const errors: any = {};

  const missingTranslationError = {
    en: 'errors.titleEnRequired',
    fi: 'errors.titleFiRequired',
  };

  if (!!values) {
    if (!!!Object.values(values.title_Translations).find((lang: any) => lang.length > 0)) {
      errors.title_Translations = missingTranslationError;
    }
  }
  
  return errors;
};

export { challengeValidator };

export default hrChallengeData;
