import { Show, useTranslate, useRecordContext } from 'react-admin';
import ShowAnswers from '../../customComponents/ShowAnswers';

const PageTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext();

  return (
    <span>
      {translate('hrSurvey.survey')}: {record && record.title}
    </span>
  );
};

const HrAnswerShow = (props) => {
  return (
    <Show {...props} title={<PageTitle />}>
      <ShowAnswers />
    </Show>
  );
};

export default HrAnswerShow;
