import { Assets } from '../_blueprints/types';
import WellbeingCompanyList from './wellbeingCompanyList';

const wellbeingCompanyAssets: Assets = {
  list: WellbeingCompanyList,
  options: {
    label: 'wellbeingCompany.wellbeingIndex',
    defaultListSort: {
      field: 'calculatedAt',
      order: 'DESC',
    },
  },
};

export default wellbeingCompanyAssets;
