import {
  BooleanInput,
  DateTimeInput,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  ImageInput,
  ImageField,
  TranslatableInputs,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  FunctionField,
  useTranslate,
  Labeled,
} from 'react-admin';
import AnswerComponent from '../../customComponents/AnswerComponent';
import { validatorDefinitions } from './fieldValidators';
import { DataType } from './types';
import { LANGUAGES } from '../../../lib/constants';
const newlineRemover = (v) => v?.replace(/[\r\n]/, '');

const fieldTypes = {
  [DataType.Bool]: ({ source, label, defaultValue, helperText }) => {
    return (
      <BooleanInput key={source} source={source} defaultValue={defaultValue} label={label} helperText={helperText} />
    );
  },
  [DataType.Text]: ({ source, validators, label, helperText }) => (
    <TextInput
      key={source}
      source={source}
      label={label}
      helperText={helperText}
      validate={validators.map((validator) => validatorDefinitions[validator]())}
      multiline
      format={newlineRemover}
      style={{
        width: '300px',
      }}
    />
  ),
  [DataType.Number]: ({ source, validators, label, helperText, defaultValue, disabled, min }) => (
    <NumberInput
      key={source}
      source={source}
      label={label}
      helperText={helperText}
      defaultValue={defaultValue}
      validate={validators.map((validator) => validatorDefinitions[validator]())}
      disabled={disabled}
      min={min}
    />
  ),
  [DataType.Image]: ({ source, validators, label, maxSize }) => (
    <ImageInput
      key={source}
      source={source}
      label={label}
      accept='image/*'
      maxSize={maxSize * 1e6}
      placeholder={<p>Drop an image to upload, or click to select it. Images up to {maxSize} MB can be used.</p>}
    >
      <ImageField
        source={source}
        title='title'
        validate={validators.map((validator) => validatorDefinitions[validator]())}
      />
    </ImageInput>
  ),
  [DataType.Reference]: ({
    source,
    reference,
    label,
    validators,
    allowEmpty,
    renderedProperty,
    helperText,
    suggestionSort,
  }) => (
    <ReferenceInput
      key={source}
      reference={reference}
      source={source}
      validate={validators.map((validator) => validatorDefinitions[validator]())}
      allowEmpty={allowEmpty}
      sort={suggestionSort}
    >
      <AutocompleteInput optionText={renderedProperty ?? 'name'} helperText={helperText} label={label} />
    </ReferenceInput>
  ),
  [DataType.ReferenceArray]: ({ source, reference, label, suggestionSort }) => (
    <ReferenceArrayInput key={source} reference={reference} source={source} label={label} sort={suggestionSort}>
      <AutocompleteArrayInput />
    </ReferenceArrayInput>
  ),
  [DataType.ReferenceImage]: ({
    source,
    reference,
    label,
    validators,
    allowEmpty,
    renderedProperty,
    helperText,
    suggestionSort,
  }) => {
    return (
      <ReferenceInput
        key={source}
        reference={reference}
        source={source}
        label={label}
        validate={validators.map((validator) => validatorDefinitions[validator]())}
        allowEmpty={allowEmpty}
        sort={suggestionSort}
      >
        <AutocompleteInput optionText={renderedProperty ?? 'name'} helperText={helperText} />
      </ReferenceInput>
    );
  },
  [DataType.DateTime]: ({ source, validators, label, helperText, defaultValue }) => (
    <DateTimeInput
      key={source}
      source={source}
      label={label}
      helperText={helperText}
      defaultValue={defaultValue}
      validate={validators.map((validator) => validatorDefinitions[validator]())}
    />
  ),
  [DataType.Select]: ({ source, validators, label, helperText, choices }) => (
    <SelectInput
      key={source}
      source={source}
      label={label}
      helperText={helperText}
      validate={validators.map((validator) => validatorDefinitions[validator]())}
      choices={choices}
      style={{
        width: '300px',
      }}
    />
  ),
  [DataType.Array]: ({ source, label }) => (
    <ArrayInput source={source} label={label}>
      <SimpleFormIterator disableReordering>
        <TranslatableInputs key='translations' locales={LANGUAGES.map((language) => language.locale)}>
          <TextInput key='translations' source='translations' label='Answer' />
        </TranslatableInputs>
      </SimpleFormIterator>
    </ArrayInput>
  ),
  [DataType.Answer]: ({ source, label, validators }) => (
    <AnswerComponent
      key={source}
      source={source}
      label={label}
      validators={validators.map((validator) => validatorDefinitions[validator]())}
      locales={LANGUAGES.map((language) => language.locale)}
    />
  ),
  [DataType.HRSurveyStatus]: ({ label }) => {
    const translate = useTranslate();
    return <Labeled label={label}><FunctionField label={label} render={(record) => translate(`hrSurvey.status.${record.surveyStatus}`)} /></Labeled>;
  },
};

const InputBlueprint = ({
  dataType,
  source,
  defaultValue,
  validators = [],
  label,
  helperText,
  reference,
  allowEmpty,
  renderedProperty,
  record,
  maxSize,
  suggestionSort,
  localizable,
  choices,
  translation,
  disabled,
  min,
}) => {
  const input = fieldTypes[dataType]({
    source,
    defaultValue,
    validators,
    label,
    helperText,
    reference,
    allowEmpty,
    renderedProperty,
    record,
    maxSize,
    suggestionSort,
    choices,
    translation,
    disabled,
    min,
  });
  return localizable ? (
    <TranslatableInputs key={source} locales={LANGUAGES.map((language) => language.locale)}>
      {input}
    </TranslatableInputs>
  ) : (
    input
  );
};

export default InputBlueprint;
